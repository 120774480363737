import Emittery from 'emittery'
import { useEffect } from 'react'
import { type EventPayload } from '~/types/events.ts'
import { logger } from './logger.ts'
import { notify } from './toasts.tsx'

const myLogger = (
	type: any,
	debugName: any,
	eventName: any,
	eventData: any,
) => {
	if (typeof eventData === 'object') {
		eventData = JSON.stringify(eventData)
	}

	if (typeof eventName === 'symbol' || typeof eventName === 'number') {
		eventName = eventName.toString()
	}

	const currentTime = new Date()
	const logTime = `${currentTime.getHours()}:${currentTime.getMinutes()}:${currentTime.getSeconds()}.${currentTime.getMilliseconds()}`
	logger.debug(
		`[${logTime}][emittery:${type}][${debugName}] Event Name: ${eventName}\n\tdata: ${eventData}`,
	)
}

export const emitter = new Emittery<EventPayload>({
	debug: {
		name: 'client-emitter',
		enabled: process.env.NODE_ENV === 'development',
		logger: myLogger,
	},
})

export function useEventListeners() {
	useEffect(() => {
		function handleUserCreated(data: EventPayload['user:created']): void {
			logger.debug('User created event')
		}

		emitter.on('user:created', handleUserCreated)
		logger.debug('Client event listeners have subscribed')

		return () => {
			emitter.clearListeners(['toast:user', 'user:created'])
			logger.debug('Client event listeners have unsubscribed')
		}
	}, [])
}
